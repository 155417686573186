import React from 'react';
import { FaPhone, FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLogoInstagram } from "react-icons/io5";
import './Header.css'
import { Fade } from "react-awesome-reveal";
import logo from '../../images/logo.jpg'





const Header = () => {
    return (
        <>
            <Header1 />
            <Header2 />
        </>
    );
}







const Header1 = () => {
    return (
        <Fade direction='left' triggerOnce={true}>
            <section className=' text-white bg-indigo flex justify-between items-center p-0 font-rubik' >
                <ul className='w-[500px] flex justify-around items-baseline pb-0'>
                    <li className='list-none flex justify-between items-center mt-3  hover:text-cyan cursor-pointer'>
                        <FaPhone size={18} className='mr-2 ' /> +91 9627147555
                    </li>
                    <span className='text-2xl'>|</span>
                    <li className='list-none flex justify-between items-center mt-3  hover:text-cyan cursor-pointer'>
                        <MdEmail size={18} className='mr-2 ' /> newlifenashamuktikendra@gmail.com
                    </li>
                </ul>
                <ul className='flex justify-around items-center mx-3  pb-0 '>
                    <li className='list-none mx-3 mt-2'> <FaFacebookF /> </li>
                    <li className='list-none mx-3 mt-2'> <IoLogoInstagram /> </li>
                    <li className='list-none mx-3 mt-2'> <FaLinkedinIn /> </li>
                    <li className='list-none mx-3 mt-2'> <FaTwitter /> </li>
                    <li className='list-none mx-3 mt-2'> <FaYoutube /> </li>
                </ul>               
            </section>
        </Fade>


    );
}

const Header2 = () => {
    return (

        <div className=' grid grid-cols-7 gap-0 p-1 md:p-1 shadow-md font-rubik sticky top-0 bg-[#ffffff] z-10'  >         
                <div className='col-span-2 flex justify-center place-items-start   p-0'>
                    <div className="">
                        <img className='w-[6rem] mt-0' src={logo} alt="logo" />
                    </div>
                    <div className="">
                        <p className='text-3xl font-nunito font-bold pt-3 m-0 p-0'>NEW LIFE</p>
                        <p className='text-sm font-rubik m-0 p-0'>Rehabilitation & De-Addiction Centre</p>
                    </div>
                   
                   
                </div>           
            <div className="col-span-4 flex items-center justify-evenly px-2 text-[#4a4848] text-md font-[420] uppercase ">
                <li className='list-none group'>
                    <span className='hover:text-cyan cursor-pointer text-cyan '>  Home</span>
                    <ul className="absolute  mt-2 w-48 bg-white shadow-sm  transform scale-y-0 z-20 group-hover:scale-y-100 origin-top transition-transform duration-300 ease-out">
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 1</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 2</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 3</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 4</span>
                        </li>
                       
                       
                    </ul>
                </li>
                <li className='list-none group'>
                    <span className='hover:text-cyan cursor-pointer'>  About</span>
                    <ul className="absolute  mt-2 w-48 bg-white shadow-lg  transform scale-y-0 z-20 group-hover:scale-y-100 origin-top transition-transform duration-300 ease-out">
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 1</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 2</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 3</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 4</span>
                        </li>
                       
                       
                    </ul>
                </li>
                <li className='list-none group'>
                    <span className='hover:text-cyan cursor-pointer'>  Services</span>
                    <ul className="absolute  mt-2 w-48 bg-white shadow-lg  transform scale-y-0 z-20 group-hover:scale-y-100 origin-top transition-transform duration-300 ease-out">
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 1</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 2</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 3</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 4</span>
                        </li>
                       
                       
                    </ul>
                </li>
             
             
                <li className='list-none group'>
                    <span className='hover:text-cyan cursor-pointer'>  Achievements</span>
                    <ul className="absolute  mt-2 w-48 bg-white shadow-lg  transform scale-y-0 z-20 group-hover:scale-y-100 origin-top transition-transform duration-300 ease-out">
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 1</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 2</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 3</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 4</span>
                        </li>
                       
                       
                    </ul>
                </li>
                <li className='list-none group'>
                    <span className='hover:text-cyan cursor-pointer'>  Contact</span>
                    <ul className="absolute  mt-2 w-48 bg-white shadow-lg  transform scale-y-0 z-20 group-hover:scale-y-100 origin-top transition-transform duration-300 ease-out">
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 1</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 2</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 3</span>
                        </li>
                        <li className="border-b border-gray-200 py-2 text-sm ">
                            <span className='font-rubik '>Option 4</span>
                        </li>
                       
                       
                    </ul>
                </li>
            </div>
            <div className='col-span-1 flex items-center justify-around'>
                
                <Fade direction='right'>
                    <button className='bg-cyan text-white p-2   text-sm focus:ring hover:ring-black font-medium' >Contact Now</button>
                </Fade >


            </div>



        </div>



    );
}
{/* <Fade direction='left' triggerOnce={true}>

</Fade > */}

export default Header;