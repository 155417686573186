import React from 'react';
import CarouselComponent from './CarouselComponent';
import HomeCourses from './HomeCourses';
import PastStatistics from './PastStatistics';
import InfiniteScrollingMainComp from './InfiniteScrolling';
import Blogs from './Blogs';
import AboutAndNotice from './AboutAndNotice';


const HomePage = () => {
  const images = [
    "https://images.unsplash.com/photo-1502602898657-3e91760cbb34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80",
    "https://images.unsplash.com/photo-1499856871958-5b9627545d1a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80",
    "https://media.istockphoto.com/photos/the-main-attraction-of-paris-and-all-of-europe-is-the-eiffel-tower-in-picture-id1185953092?k=6&m=1185953092&s=612x612&w=0&h=SNiShskOfwQ7Sys5TX0eb5eBxHobktWUfZGrox5LMyk=",
    "https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Ym9va3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80",
    "https://images.ctfassets.net/hrltx12pl8hq/3MbF54EhWUhsXunc5Keueb/60774fbbff86e6bf6776f1e17a8016b4/04-nature_721703848.jpg?fit=fill&w=480&h=270",
    "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aHVtYW58ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80",

  ].map((image) => ({
    id: crypto.randomUUID(),
    image
  }));


  return (
    <div className=''>
      <div className="w-full md:grid grid-cols-2   bg-news-image bg-contain">
        <div className="flex justify-center items-center  ">
          <div className="mx-auto md:px-12 px-5 py-4 ">
            <p className="text-md  text-cyan font-rubik font-medium">
              SAY NO TO DRUGS
            </p>
            <p className="lg:text-4xl text-3xl text-black font-nunito font-bold ">
              Education during COVID-19 moving towards e-learning
            </p>
            <p className="lg:text-lg text-md text-black font-rubik ">
              Every act of conscious learning requires the willingness to
              suffer an injury to one’s self-esteem during COVID-19.
            </p>

            <div className="flex items-center ">
              <span className='cursor-pointer text-white  bg-cyan rounded-full md:py-2 md:px-4 px-3 py-1  font-bold '> FREE COUNSELLING</span>
            </div>
          </div>
         

        </div>
        <div className="flex justify-center items-center py-3 mx-3">
          <img className='rounded w-full h-full' src="https://media.istockphoto.com/id/1203763298/photo/teenager-in-a-yellow-sweatshirt-refuses-different-types-of-alcohol.jpg?s=612x612&w=0&k=20&c=AHrhLcPGTuUs90SiD2Xvf_NgckjHeiVkvDPIwcJDQQc=" alt="" />

        </div>



      </div>
      {/*  */}
      <AboutAndNotice />
      <HomeCourses />
      <PastStatistics />
      {/* <InfiniteScrollingMainComp images={images} speed={images.length * 2000} /> */}
      {/* <Blogs /> */}
    </div>
  );
}

export default HomePage;
