import React from 'react';
import CourseCard from './CourseCard';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { PiTildeLight } from "react-icons/pi";
import { Fade, Rotate } from "react-awesome-reveal";

const HomeCourses = () => {
    const courses = [
        { id: 1, title: "BULL'S EYE FOR JEE", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://www.allen.ac.in/assets/img/new-homepage/jee-main.png" },
        { id: 2, title: "NUCLEUS FOR JEE", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://www.allen.ac.in/assets/img/new-homepage/medpg.png" },
        { id: 3, title: "STERLING FOR JEE", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://www.allen.ac.in/assets/img/new-homepage/jeemain.png" },
        { id: 4, title: "BULL'S EYE FOR NEET", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://bansalclassesguwahati.com/wp-content/uploads/2024/01/courses-neet.jpg" },
        { id: 5, title: "NUCLEUS FOR NEET", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://www.allen.ac.in/assets/img/new-homepage/allendigital.png" },
        { id: 6, title: "STERLING FOR NEET", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://www.allen.ac.in/assets/img/new-homepage/medpg.png" },
        { id: 7, title: "OCTAGON", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://www.allen.ac.in/assets/img/new-homepage/commerce.png" },
        { id: 8, title: "PEARL", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://www.allen.ac.in/assets/img/new-homepage/jee-main.png" },
        { id: 9, title: "ZENITH", description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem odit possimu", imgurl: "https://www.allen.ac.in/assets/img/new-homepage/jee-main.png" },
    ];


    return (
        < div className=' '>
            <div className="bg-news-image bg-cover bg-center pb-3">
                <Fade triggerOnce={true} direction='left'>
                    <div className='h-[4.5rem] p-0 flex justify-center  text-7xl  text-cyan text-center  font-bold '><PiTildeLight className='p-0 m-0 transform' style={{ transform: 'scaleX(1.5) scaleY(0.7)' }} size={100} /></div>
                </Fade>
                <Fade triggerOnce={true} direction='right'>
                    <p className='text-4xl text-slate-800 text-center pt-0 mt-0 font-bold font-nunito'>Our Services</p>
                </Fade>


                {/* <Fade triggerOnce={true} direction='up'>
                    <div className="flex justify-center mt-4">
                        <div className="md:flex justify-between w-[47rem] ">
                            <button className=' rounded-sm bg-[#ffffff] py-1.5 px-4 font-nunito font-extrabold hover:text-cyan hover:bg-white hover:shadow-md text-cyan bg-white shadow-md '>All Courses</button>
                         
                            <button className='rounded-sm bg-cyan text-[#ffffff] py-1.5 px-4 font-nunito font-extrabold hover:text-[#21a7d0] hover:bg-white hover:shadow-md transition duration-300 ease-in-out transform hover:scale-105'>JEE (Mains + Advanced) </button>
                            <button className='rounded-sm bg-cyan text-[#ffffff] py-1.5 px-4 font-nunito font-extrabold hover:text-[#21a7d0] hover:bg-white hover:shadow-md transition duration-300 ease-in-out transform hover:scale-105'>NEET UG</button>
                            <button className='rounded-sm bg-cyan text-[#ffffff] py-1.5 px-4 font-nunito font-extrabold hover:text-[#21a7d0] hover:bg-white hover:shadow-md transition duration-300 ease-in-out transform hover:scale-105'>Pre Foundation</button>
                        </div>
                    </div>
                </Fade> */}
            </div>

            <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 p-8 pt-2 lg:px-28 ">
                {
                    courses.map((course) => {
                        return <Rotate triggerOnce={true}>
                            <div className=' cursor-pointer p-2 bg-[#e7f4f6] rounded   transition delay-75 duration-300 ease-in-out hover:scale-y-110' >
                                <div className="w-100 sm:h-36  h-44  p-1">
                                    <img className='rounded-lg w-100  sm:h-32 h-40 object-fill' src={course.imgurl} alt="" />
                                </div>
                                <div className=" p-2  " >
                                    <p className='text-sm font-bold break-words my-1 font-nunito'>{course.title}</p>
                                    <p className='text-sm text-gray-600 break-words font-rubik'>
                                        {course.description}
                                    </p>
                                </div>
                                <div className='p-2'>
                                    <button className='bg-cyan p-1 text-white font-nunito text-sm rounded-sm'>Click Here</button>
                                </div>
                            </div>
                        </Rotate>


                    })


                }

            </div>


        </div>

        // <>
        //     <div className='container d-lg-flex justify-content-between pt-4'>            
        //         <div className="col-lg-6 d-md-flex justify-content-around p-1">
        //             <div className=" col-md-5  text-center d-flex justify-content-center align-items-center mb-sm-1 mb-2">
        //                 <h1 className='text-primary3 fw-bold'> Courses
        //                     <span className='d-md-block'> & </span>
        //                     Fee</h1>
        //             </div>
        //             <div className="col-md-5 mb-sm-1 mb-2">
        //                 <CourseCard title={courses[0].title} imgurl={courses[0].imgurl} description={courses[0].description} />
        //             </div>
        //         </div>
        //         <div className="col-lg-6 d-md-flex justify-content-around p-1">
        //             <div className="col-md-5 mb-sm-1 mb-2">
        //                 <CourseCard title={courses[1].title} imgurl={courses[1].imgurl} description={courses[1].description} />
        //             </div>
        //             <div className="col-md-5 mb-sm-1 mb-2">
        //                 <CourseCard title={courses[2].title} imgurl={courses[2].imgurl} description={courses[2].description} />
        //             </div>
        //         </div>
        //     </div>
        //     <div className='container d-lg-flex justify-content-between pt-4'>
        //         <div className="col-lg-6 d-md-flex justify-content-around p-1">
        //             <div className="col-md-5 mb-sm-1 mb-2">
        //                 <CourseCard title={courses[3].title} imgurl={courses[3].imgurl} description={courses[3].description} />
        //             </div>
        //             <div className="col-md-5 mb-sm-1 mb-2">
        //                 <CourseCard title={courses[4].title} imgurl={courses[4].imgurl} description={courses[4].description} />
        //             </div>
        //         </div>
        //         <div className="col-lg-6 d-md-flex justify-content-around p-1">
        //             <div className="col-md-5 mb-sm-1 mb-2">
        //                 <CourseCard title={courses[5].title} imgurl={courses[5].imgurl} description={courses[5].description} />
        //             </div>
        //             <div className="col-md-5 mb-sm-1 mb-2">
        //                 <CourseCard title={courses[6].title} imgurl={courses[6].imgurl} description={courses[6].description} />
        //             </div>
        //         </div>
        //     </div>
        // </>


    );
}

export default HomeCourses;
