import React from 'react';
import tickImg from '../images/progress-arrow.png'
const PastStatistics = () => {
    return (
        <div className='container  d-lg-flex justify-content-between align-items-center pt-4 '>
            <div className=" col-12 col-lg-7 transforming-lives1">
                <div className="">
                    <h2 className='heading responsive-h3'>Transforming lives since 2020</h2>
                </div>
                <div className=" d-flex justify-content-around">
                    <div className="col-4 border-end border-end-3 border-black">
                        <h2 className='responsive-h2'>100+</h2>
                        <p className='responsive-para fw-semibold'>Patient Treated
                            <span className='d-block'>since 2020</span>
                        </p>
                    </div>
                    <div className=" col-5 ">
                        <h2 className='responsive-h2'>800+</h2>
                        <p className='responsive-para fw-semibold'>Patient Treated
                            <span className='d-block'> since 2020</span>
                        </p>
                    </div>
                </div>

            </div>
            <div className="col-12 col-lg-4 transforming-lives2 ">
                <div className="d-lg-block d-flex justify-content-around">
                    <div className="col-lg-12 col-4 p-3 d-flex justify-content-start align-items-center">
                        <div className="col-2  d-lg-block d-none pe-3 p-1 ">
                            <span style={{ borderRadius: "50%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }} className='bg-white'>
                                <img  src={tickImg} alt="" style={{ width: '2rem', height: '2rem' }} />
                            </span>
                        </div>
                        <div className=" ">
                            <span className='responsive-h3 fw-bold d-block'> 19+</span>
                            <span className='responsive-para2'>PATIENT TREATED</span>
                        </div>
                    </div>
                    <div className="col-lg-12 col-4 p-3 d-flex justify-content-start align-items-center">
                        <div className="col-2  d-lg-block d-none  pe-3 p-1 ">
                            <span style={{ borderRadius: "50%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }} className='bg-white'>
                                <img src={tickImg} alt="" style={{ width: '2rem', height: '2rem' }} />
                            </span>
                        </div>
                        <div className=" ">
                            <span className='responsive-h3 fw-bold d-block'> 27+</span>
                            <span className='responsive-para2'> PATIENT TREATED</span>
                        </div>
                    </div>
                    <div className="col-lg-12 col-4 p-3 d-flex justify-content-start align-items-center">
                        <div className="col-2  d-lg-block d-none  pe-3 p-1 ">
                            <span style={{ borderRadius: "50%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }} className='bg-white'>
                                <img src={tickImg} alt="" style={{ width: '2rem', height: '2rem' }} />
                            </span>
                        </div>
                        <div className=" ">
                            <span className='responsive-h3 fw-bold d-block'> 20+</span>
                            <span className='responsive-para2'> PATIENT TREATED</span>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    );
}

export default PastStatistics;
