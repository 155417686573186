import React, { useContext, useState } from 'react';
import { Accordion, AccordionContext, Button, Card, Offcanvas, useAccordionButton } from 'react-bootstrap';
import { VscMenu } from "react-icons/vsc";
import logo from '../../images/logo.jpg'


const PINK = 'rgba(255, 192, 203, 0.6)';
const BLUE = 'rgba(0, 0, 255, 0.6)';

function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <span
            type="button"
            style={{}}
            onClick={decoratedOnClick}
        >
            {children}
        </span>
    );
}
const listItems = [
    { id: 1, name: 'Home' },
    { id: 2, name: 'About' },
    { id: 3, name: 'Courses' },
    { id: 4, name: 'Registration' },
    { id: 5, name: 'Boost' },
    { id: 6, name: 'Achievements' },
    { id: 7, name: 'Contact' }
];


const MobileMenu = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div >
            {/* <Accordion defaultActiveKey="0" className=''>
                <Card>
                    <Card.Header >
                        <div className="flex justify-between bg-white w-full">
                            <div className='w-2/12 '>
                                <img className='logo' src={logo} alt="logo" />
                            </div>
                            <div className='flex justify-center items-center'>
                                <ContextAwareToggle eventKey="1"><VscMenu color='' size={25} /></ContextAwareToggle>
                            </div>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <ul className='fw-semibold text-uppercase text-primary1'>
                                {
                                    listItems.map(item => {
                                        return <li key={item.id} className='custom-list list-unstyled   p-1 border-bottom border-1 border-grey'> {item.name} </li>
                                    })
                                }
                            </ul>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion> */}

            <div className="w-full flex justify-between items-center px-2">
                <div className="w-[25rem] flex justify-start items-center">
                    <span className='w-1/4'> <img src={logo} alt="logo image" /></span>
                    <span className='text-2xl font-nunito font-bold'>NEW LIFE
                        <span className='block text-sm font-nunito font-bold'>Rehabilitation & De-Addiction Centre</span>
                    </span>
                </div>

                <div className="">
                    <VscMenu color='' size={30} onClick={handleShow} className='cursor-pointer' />
                </div>

            </div>
            <Offcanvas show={show} onHide={handleClose} backdrop={true} scroll={true} style={{ width: '16rem' }} >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    I will not close if you click outside of me.
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default MobileMenu;