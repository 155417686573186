
import HomePage from "./components/Home/HomePage";
import Header from './components/Header/Header'
import { useEffect, useState } from "react";
import MobileMenu from "./components/Header/MobileMenu";
import Footer from "./components/Footer/Footer";



function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  // Effect to handle screen resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="App ">
      {isMobile ? <MobileMenu /> : <Header />}
      <HomePage />
      {/* <Footer /> */}

    </div>
  );
}

export default App;
