import React from 'react';
import founderImage from '../../images/founderImage.jpg'
import CarouselComponent from './CarouselComponent';

const AboutAndNotice = () => {
  const noticeArray = [
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
  ]
  return (
    <div className=' mt-3 pb-10 '>

      <div className="grid grid-cols-1 z-1">
        <div className=" bg-[#e7f4f6] p-12 rounded ">
          <p className='text-cyan text-lg font-[500] font-rubik'>ABOUT US</p>
          <p className='text-4xl font-nunito font-bold leading-tight'>World Best Virtual Learning Network Educavo eLearning</p>
          <p className='text-lg font-rubik text-[#505050] leading-8'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, eius to mod tempor incidi dunt ut labore et dolore magna aliqua. Ut enims ad minim veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit, eius to mod tempor incidi dunt ut labore et dolore magna aliqua. Ut enims ad minim veniam.Lorem sum dolor sit amet, consectetur adipisicing.</p>

          <div className="flex mt-5">
            <div className="h-[8rem] w-[8rem] ">
              <img src={founderImage} alt="" className='rounded-full' />
            </div>
            <div className="ml-7 ">
              <span className='pt-0.1'>
                <img src="https://keenitsolutions.com/products/html/educavo/assets/images/about/sign.png" alt="" />
              </span>
              <span className='p-1 text-2sm font-rubik font-medium'>
                CEO & Founder of Educavo
              </span>
            </div>


          </div>
        </div>
        {/* <div className="">
          <CarouselComponent />
        </div> */}

      </div>

    </div>
  );
}

export default AboutAndNotice;
